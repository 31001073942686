import React from 'react'
import Klaviyo from '../../../components/Integrations/IntegrationsDetails/Klaviyo'
import PageSeo from '../../../components/SEO'

function index() {
    return (
        <div>
            <PageSeo pageTitle="Klaviyo"/>
            <Klaviyo/>
        </div>
    )
}

export default index
