import React from "react"
import PricingNavbar from "../../Pricing/PricingNavbar/PricingNavbar"

import "../../../styles/integrations/IntegrationsDetails.css"
import klaviyo from "../../../../static/assets/klaviyo.png"
import klaviyo2 from "../../../../static/assets/klaviyo3.png"
import InfluencerFooter from "../../LandingSubComponents/InfluencerFooter/InfluencerFooter"
function Klaviyo() {



  return (
    <div className="IntegrationsDetailsCol">
      <PricingNavbar />
      <div className="container is-fluid integrationsDetailsContainer integrationsDetailsHeaderBox">
        <div className="columns is-vcentered is-multiline is-mobile ">
          <div className="column has-text-centered-mobile is-offset-1-desktop is-4-desktop is-4-tablet is-12-mobile">
              <div className="inteDetailsImgBox ">
              <img
              src={klaviyo2}
              alt="klaviyo"
              title="kalviyo"
              loading="lazy"
              className="image inteDetailsImg pr-3 is-hidden-mobile"
            />
              <img
              src={klaviyo}
              alt="klaviyo"
              title="kalviyo"
              loading="lazy"
              className="image inteDetailsImg is-hidden-tablet"
            />
              </div>
        
          </div>
          <div className="column is-3-desktop is-3-tablet is-12-mobile">
              <h1 className=" has-text-centered  inteDetailsHeader has-text-black  is-size-4-mobile is-size-3-desktop is-size-4-tablet">
                  Klaviyo
              </h1>
          </div>
          <div className="column is-4-desktop  is-5-tablet is-12-mobile  has-text-right-desktop has-text-right-tablet">
              <div className="has-text-centered-mobile">
              <a href="http://klaviyo.com/?from=influencerbit" target="_blank" className="button is-medium has-text-white is-uppercase inteDetailsVisitBtn">
               visit klaviyo
              </a>
              </div>
          </div>
        </div>
      </div>
      <div className="container is-fluid integrationsDetailsContainer">
          <div className="columns is-multiline is-mobile">
            <div className="column is-6-desktop is-6-tablet is-12-mobile">
                <div className="inteDeatilsCard">
                    <h2 className="has-text-left has-text-white is-size-4 inteDetailsCardHeader">
                        What is Klaviyo?
                    </h2>
                    <p className="mt-3 inteDetailsCardInfo has-text-white is-size-6">
                      Klaviyo is one of the best E-Commerce E-mail sender (and now sms too) solution out there with a bunch of built in templates and flows. From cart abandonment to capturing user's email / sms via pop-ups, Klaviyo is one of the most used integration on Shopify.
                    </p>
                </div>
            </div>
            <div className="column is-6-desktop is-6-tablet is-12-mobile">
                <div className="inteDeatilsCard">
                    <h2 className="has-text-left has-text-white is-size-4 inteDetailsCardHeader">
                    How Klaviyo integrates
with Influencerbit:
                    </h2>
                    <p className="mt-3 inteDetailsCardInfo has-text-white is-size-6">
                      Influencerbit helps brands identify which of their customers are micro-influencers and tracks them whenever they mention the connected brand. Influencerbit adds an optional field to your Klaviyo subscriber popup or at the order checkout form that captures your customers' Instagram handles. With out integration, this instagram username is passed to Influencerbit where we get their social insights like Follower-following counts, influencer grade, etc and sync this to Klaviyo. You can then trigger specific flows when the brand is mentioned by the influencer. In Short, we make Klaviyo into Influencer marketing mammoth!
                    </p>
                </div>
            </div>
          </div>
      </div>
      <div className="mt-3">
          <InfluencerFooter/>
      </div>
    </div>
  )
}

export default Klaviyo
